#root {
	width: 100%;
	height: 100%;
	&:before {
		content: '';
		width: 450px;
		height: 450px;
		border-radius: 50%;
		box-shadow: 0 0 10px rgba(0,0,0,0.2);
		position: fixed;
		top: -100px;
		right: -100px;
	}
	&:after {
		content: '';
		width: 350px;
		height: 350px;
		border-radius: 50%;
		box-shadow: 0 0 10px rgba(0,0,0,0.2);
		position: fixed;
		bottom: -100px;
		left: -100px;
	}
	h3 {
		font-size: 20px;
		font-weight: 600;
		margin: 0 0 20px 0;
	}
	.form-group {
		margin-bottom: 15px;
		.form-label {
			font-size: 12px;
		}
		.form-control {
			width: 100%;
			border: none;
			border-bottom: 1px solid var(--color-border);
			border-radius: 0;
			min-height: 34px;
			padding: 0;
			&:focus {
				outline: none;
			}
		}
	}
	.btn-primary {
		background-color: var(--color-primary);
		border: none;
		border-radius: 10px;
		min-height: 40px;
		padding: 0 20px;
		color: var(--color-white);
		cursor: pointer;
	}
	a {
		color: var(--color-bg);
		text-decoration: none;
		&:hover {
			text-decoration: underline;
		}
	}
}

.main-app {
	display: flex;
	flex-direction: column;
	height: 100%;
	position: relative;
	z-index: 2;
	overflow: hidden;
	.page-body {
		background-color: var(--color-white);
		margin: 0 50px;
		border-radius: 30px 30px 0 0;
		padding: 30px;
		flex: 1;
		overflow: hidden;
		.page {
			overflow: auto;
			height: 100%;
		}
	}
}

.thumbnail-50 {
	width: 50px;
	height: 50px;
	border-radius: 5px;
	object-fit: cover;
}
.thumbnail-60 {
	width: 60px;
	height: 60px;
	border-radius: 5px;
	object-fit: cover;
}
