.login-wrap {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    .logo-container {
        width: 300px;
    }
    .login-box {
        background-color: var(--color-white);
        border-radius: 30px;
        padding: 30px;
        display: flex;
        flex-direction: column;
        width: 360px;
        .login-footer {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }
}

@media screen and (max-width:1024px) {
    .login-wrap {
        flex-direction: column;
        .logo-container {
            height: 300px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}

@media screen and (max-width:768px) {
    .login-wrap {
        justify-content: flex-start;
        .logo-container {
            height: 200px;
        }
        .login-box {
            border-radius: 30px 30px 0 0;
            width: 100%;
            flex: 1;
        }
    }
}
