#root header {
    height: 80px;
    color: var(--color-white);
    display: flex;
    justify-content: space-between;
    padding: 0 50px;
    align-items: center;
    gap: 20px;
    .header-logo {
        flex: 1;
        img {
            max-height: 60px;
        }
    }
    .main-nav {
        display: flex;
        margin: 0;
        list-style: none;
        gap: 20px;
        padding: 0;
        a {
            color: var(--color-white);
        }
    }
}
