.table-wrap {
    table {
        width: 100%;
        table-layout: fixed;
        border-collapse: collapse;
        thead {
            th {
                text-align: left;
                padding-top: 10px;
                padding-bottom: 10px;
                
                &:first-child {
                    padding-left: 10px;
                    border-radius: 10px 0 0 10px;
                }
                &:last-child {
                    text-align: right;
                    padding-right: 10px;
                    border-radius: 0 10px 10px 0;
                }
                img {
                    display: block;
                }
            }
        }
        tbody {
            tr {
                &:hover {
                    background-color: #C5CFDA;
                }
                td {
                    text-align: left;
                    padding-top: 10px;
                    padding-bottom: 10px;
                    
                    &:first-child {
                        padding-left: 10px;
                        border-radius: 10px 0 0 10px;
                    }
                    &:last-child {
                        text-align: right;
                        padding-right: 10px;
                        border-radius: 0 10px 10px 0;
                    }
                    img {
                        display: block;
                    }
                }
            }
        }
    }
}
